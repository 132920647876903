<template>
  <Menubar :model="items">
    <template #start>
      <img
        alt="logo"
        @click="$router.push('/admin/')"
        src="../assets/logo.png"
        height="40"
        class="p-mr-2"
      />
    </template>
    <template #end>
      <Button @click="logout" icon="pi pi-power-off" class="p-button-danger" />
    </template>
  </Menubar>
</template>

<script>
import Menubar from 'primevue/menubar';
export default {
  components: {
    Menubar,
  },
  name: 'app-header',
  data() {
    return {
      items: [
        {
          label: 'الرئيسيه',
          icon: 'pi pi-fw pi-home',
          to: '/admin',
        },
      ],
    };
  },
  methods: {
    logout() {
      delete localStorage.admin;
      location.reload();
    },
  },
  created() {
    const user = JSON.parse(localStorage.admin);

    this.items = [
      {
        label: 'الاعدادت',
        icon: 'pi pi-fw pi-cog',
        items: [
          {
            label: 'الصفحه الرئيسيه',
            icon: 'pi pi-fw pi-home',
            to: '/admin/home',
          },
          {
            label: 'يقول عنا',
            icon: 'pi pi-fw pi-megaphone',
            to: '/admin/system/says',
          },
          {
            label: 'اعمالنا',
            icon: 'pi pi-fw pi-table',
            to: '/admin/system/works',
          },
          // clients
          {
            label: 'عملائنا',
            icon: 'pi pi-fw pi-users',
            to: '/admin/system/clients',
          },
          // pages
          {
            label: 'الصفحات',
            icon: 'pi pi-fw pi-file',
            to: '/admin/system/pages',
          },
          {
            label: 'السلايدر',
            icon: 'pi pi-step-forward-alt',
            to: '/admin/system/slider',
          },
        ],
      },
      {
        label: 'مستخدمين النظام',
        icon: 'pi pi-fw pi-user',
        to: '/admin/users/admins',
      },
      {
        label: 'الاقسام',
        icon: 'pi pi-fw pi-sitemap',
        to: '/admin/system/sections',
      },

      {
        label: 'المنتاجات',
        icon: 'pi pi-fw pi-tag',
        to: '/admin/system/products',
      },

      {
        label: 'الطلبات',
        icon: 'pi pi-fw pi-shopping-bag',
        to: '/admin/system/orders',
      },

      {
        label: 'اتصل بنا',
        icon: 'pi pi-fw pi-phone',
        to: '/admin/system/contact',
      },
    ];
  },
};
</script>
